import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));
const ResetPasswordFinal = Loadable(lazy(() => import('../views/authentication/ResetPasswordFinal')));

/* ****Pages***** */
const Dashboard1 = Loadable(lazy(() => import('../views/dashboards/Dashboard1')));
const Dashboard2 = Loadable(lazy(() => import('../views/dashboards/Dashboard2')));
const Dashboard3 = Loadable(lazy(() => import('../views/dashboards/Dashboard3')));

/* ****Apps***** */
const Chats = Loadable(lazy(() => import('../views/apps/chats/Chats')));
const Notes = Loadable(lazy(() => import('../views/apps/notes/Notes')));
const Email = Loadable(lazy(() => import('../views/apps/email/Email')));
const Shop = Loadable(lazy(() => import('../views/apps/shop/Shop')));
const QuillEditor = Loadable(lazy(() => import('../views/quill-editor/QuillEditor')));
const Treeview = Loadable(lazy(() => import('../views/treeview/Treeview')));
const Pricing = Loadable(lazy(() => import('../views/pricing/Pricing')));
const CustomTimeline = Loadable(lazy(() => import('../views/timeline/CustomTimeline')));
const CustomTypography = Loadable(lazy(() => import('../views/typography/CustomTypography')));
const Calendar = Loadable(lazy(() => import('../views/apps/calendar/ACalendar')));
const CustomerEdit = Loadable(lazy(() => import('../views/apps/customers/CustomerEdit')));
const CustomerLists = Loadable(lazy(() => import('../views/apps/customers/CustomerLists')));
/* ****Tables***** */
const BasicTable = Loadable(lazy(() => import('../views/tables/BasicTable')));
const PaginationTable = Loadable(lazy(() => import('../views/tables/PaginationTable')));
const EnhancedTable = Loadable(lazy(() => import('../views/tables/EnhancedTable')));
const CollapsibleTable = Loadable(lazy(() => import('../views/tables/CollapsibleTable')));
const FixedHeaderTable = Loadable(lazy(() => import('../views/tables/FixedHeaderTable')));

// form elements
const ExAutoComplete = Loadable(lazy(() => import('../views/form-elements/ExAutoComplete')));
const ExButton = Loadable(lazy(() => import('../views/form-elements/ExButton')));
const ExCheckbox = Loadable(lazy(() => import('../views/form-elements/ExCheckbox')));
const ExDateTime = Loadable(lazy(() => import('../views/form-elements/ExDateTime')));
const ExRadio = Loadable(lazy(() => import('../views/form-elements/ExRadio')));
const ExSlider = Loadable(lazy(() => import('../views/form-elements/ExSlider')));
const ExSwitch = Loadable(lazy(() => import('../views/form-elements/ExSwitch')));
const FormWizard = Loadable(lazy(() => import('../views/form-layouts/FormWizard')));
// form layouts
const FormLayouts = Loadable(lazy(() => import('../views/form-layouts/FormLayouts')));
const FormCustom = Loadable(lazy(() => import('../views/form-layouts/FormCustom')));

// widgets
const WidgetFeed = Loadable(lazy(() => import('../views/widgets/widget-feed/WidgetFeed')));
const WidgetApps = Loadable(lazy(() => import('../views/widgets/widget-apps/WidgetApps')));

// userprofile
const UserProfile = Loadable(lazy(() => import('../views/user-profile/UserProfile')));
const ShopDetail = Loadable(lazy(() => import('../views/apps/shop-detail/ShopDetail')));

// chart
const LineChart = Loadable(lazy(() => import('../views/charts/LineChart')));
const GredientChart = Loadable(lazy(() => import('../views/charts/GredientChart')));
const DoughnutChart = Loadable(lazy(() => import('../views/charts/DoughnutChart')));
const AreaChart = Loadable(lazy(() => import('../views/charts/AreaChart')));
const ColumnChart = Loadable(lazy(() => import('../views/charts/ColumnChart')));
const CandlestickChart = Loadable(lazy(() => import('../views/charts/CandlestickChart')));
const RadialbarChart = Loadable(lazy(() => import('../views/charts/RadialbarChart')));

// icons
const ReactIcons = Loadable(lazy(() => import('../views/icons/ReactIcons')));

// Alert
const ExAlert = Loadable(lazy(() => import('../views/alert/ExAlert')));



// form layouts
const Home1 = Loadable(lazy(() => import('../views/kaaytchop/home/Home1')));
const Restaurant = Loadable(lazy(() => import('../views/kaaytchop/Restaurants/Restaurant')));
const CaisseEnregistreuse = Loadable(lazy(() => import('../views/kaaytchop/Restaurants/CaisseEnregistreuse')));
const RestaurantDetails = Loadable(lazy(() => import('../views/kaaytchop/Restaurants/RestaurantDetails')));
const OrderList = Loadable(lazy(() => import('../views/kaaytchop/Restaurants/OrderList')));
const OrderListOnline = Loadable(lazy(() => import('../views/kaaytchop/Restaurants/OrderListOnline')));
const ClientList = Loadable(lazy(() => import('../views/kaaytchop/Restaurants/ClientList')));
const TableauBord = Loadable(lazy(() => import('../views/kaaytchop/Restaurants/TableauBord')));
const Zhistorique = Loadable(lazy(() => import('../views/kaaytchop/Restaurants/Zhistorique')));
const PromoCodes = Loadable(lazy(() => import('../views/kaaytchop/Restaurants/PromoCodes')));
const AnnuaireAdd = Loadable(lazy(() => import('../views/kaaytchop/forms/AnnuaireAdd')));
const GeneralCategorieDetails = Loadable(lazy(() => import('../views/kaaytchop/GestionProduits/produits-components/CategoryDetails')));
const Kitchendisplayscreen = Loadable(lazy(() => import('../views/kaaytchop/Kds/Kitchendisplayscreen')));
const Orderdisplayscreen = Loadable(lazy(() => import('../views/kaaytchop/Ods/Orderdisplayscreen')));

const HomeCatalogue = lazy(() => import('../views/kaaytchop/GestionCatalogue/HomeCatalogue'));

// LIVREUR 

const OrderListLivreur = Loadable(lazy(() => import('../views/kaaytchop/Livreurs/OrderListLivreur')));
const LivreurDetails = Loadable(lazy(() => import('../views/kaaytchop/Livreurs/LivreurDetails')));
// new pour recuperer les commandes courses et food
const HomeDeliver = Loadable(lazy(() => import('../views/kaaytchop/Livreurs/HomeDeliver')));

// CUSTOMER 

const OrderListCustomer = Loadable(lazy(() => import('../views/kaaytchop/Customers/OrderListCustomer')));
const CustomerDetails = Loadable(lazy(() => import('../views/kaaytchop/Customers/CustomerDetails')));

// ADMINISTRATION 

const Admin = Loadable(lazy(() => import('../views/kaaytchop/Administration/Admin')));
const RestaurantList = Loadable(lazy(() => import('../views/kaaytchop/Administration/RestaurantList')));
const OrderListGlobal = Loadable(lazy(() => import('../views/kaaytchop/Administration/OrderListOnline')));
/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      // { path: '/', element: <Navigate to="/dashboards/dashboard1" /> },

      { path: '/', exact: true, element: <Home1 /> },

      { path: '/kaaytchop/Restaurant/:restoId', exact: true, element: <Restaurant /> },
      { path: '/kaaytchop/restaurant-caisse-enregistreuse', exact: true, element: <CaisseEnregistreuse /> },
      { path: '/kaaytchop/restaurant-mes-commandes', exact: true, element: <OrderList /> },
      { path: '/kaaytchop/restaurant-mes-commandes-online', exact: true, element: <OrderListOnline /> },
      { path: '/kaaytchop/restaurant-mes-clients', exact: true, element: <ClientList /> },
      { path: '/kaaytchop/restaurant-mon-tableau-bord', exact: true, element: <TableauBord /> },
      { path: '/kaaytchop/restaurant-z-historique', exact: true, element: <Zhistorique /> },
      { path: '/kaaytchop/restaurant-nes-codes-promos', exact: true, element: <PromoCodes /> },
      { path: '/kaaytchop/ecran-de-cuisine-kds', exact: true, element: <Kitchendisplayscreen /> },
      { path: '/kaaytchop/ecran-de-suivi-client', exact: true, element: <Orderdisplayscreen /> },

      { path: '/kaaytchop/ajouter-annuaire', exact: true, element: <AnnuaireAdd /> },
      { path: '/kaaytchop/restaurant-gestion-catalogue', exact: true, element: <HomeCatalogue /> },
      { path: '/kaaytchop/GeneralCategorieDetails', exact: true, element: <GeneralCategorieDetails /> },
      { path: '/kaaytchop/restaurant-mon-compte', exact: true, element: <RestaurantDetails /> },

      { path: '/kaaytchop/livreur-mes-livraisons', exact: true, element: <OrderListLivreur /> },
      { path: '/livreur-commandes', exact: true, element: <HomeDeliver /> },
      { path: '/kaaytchop/livreur-mon-profil', exact: true, element: <LivreurDetails /> },

      { path: '/kaaytchop/admin-dashboard', exact: true, element: <Admin /> },
      { path: '/kaaytchop/admin-restaurants', exact: true, element: <RestaurantList /> },
      { path: '/kaaytchop/admin-commandes', exact: true, element: <OrderListGlobal /> },



      { path: '/kaaytchop/client-mes-commandes', exact: true, element: <OrderListCustomer /> },
      { path: '/kaaytchop/client-mon-profil', exact: true, element: <CustomerDetails /> },


      { path: '/dashboards/dashboard1', exact: true, element: <Dashboard1 /> },
      { path: '/dashboards/dashboard2', exact: true, element: <Dashboard2 /> },
      { path: '/dashboards/dashboard3', exact: true, element: <Dashboard3 /> },
      { path: '/customers/lists', exact: true, element: <CustomerLists /> },
      { path: '/chats', element: <Chats /> },
      { path: '/notes', element: <Notes /> },
      { path: '/email', element: <Email /> },
      { path: '/shop/lists', element: <Shop /> },
      { path: '/calendar', element: <Calendar /> },
      { path: '/customers/edit', element: <CustomerEdit /> },
      { path: '/tables/basic-table', element: <BasicTable /> },
      { path: '/tables/pagination-table', element: <PaginationTable /> },
      { path: '/tables/enhanced-table', element: <EnhancedTable /> },
      { path: '/tables/collapsible-table', element: <CollapsibleTable /> },
      { path: '/tables/fixed-header-table', element: <FixedHeaderTable /> },
      { path: '/form-layouts/form-layouts', element: <FormLayouts /> },
      { path: '/form-elements/autocomplete', element: <ExAutoComplete /> },
      { path: '/form-elements/button', element: <ExButton /> },
      { path: '/form-elements/checkbox', element: <ExCheckbox /> },
      { path: '/form-elements/date-time', element: <ExDateTime /> },
      { path: '/form-elements/radio', element: <ExRadio /> },
      { path: '/form-elements/slider', element: <ExSlider /> },
      { path: '/form-elements/switch', element: <ExSwitch /> },
      { path: '/form-layouts/form-wizard', element: <FormWizard /> },
      { path: '/widgets/widget-feed', element: <WidgetFeed /> },
      { path: '/widgets/widget-apps', element: <WidgetApps /> },
      { path: '/user-profile', element: <UserProfile /> },
      { path: '/shop/shop-detail', element: <ShopDetail /> },
      { path: '/charts/line-chart', element: <LineChart /> },
      { path: '/charts/gredient-chart', element: <GredientChart /> },
      { path: '/charts/doughnut-pie-chart', element: <DoughnutChart /> },
      { path: '/charts/area-chart', element: <AreaChart /> },
      { path: '/charts/column-chart', element: <ColumnChart /> },
      { path: '/charts/candlestick-chart', element: <CandlestickChart /> },
      { path: '/charts/radialbar-chart', element: <RadialbarChart /> },
      { path: '/react-icons', element: <ReactIcons /> },
      { path: '/form-layouts/form-custom', element: <FormCustom /> },
      { path: '/quill-editor', element: <QuillEditor /> },
      { path: '/treeview', element: <Treeview /> },
      { path: '/pricing', element: <Pricing /> },
      { path: '/timeline', element: <CustomTimeline /> },
      { path: '/typography', element: <CustomTypography /> },
      { path: '/alert', element: <ExAlert /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'change-password/:userid&:token', element: <ResetPasswordFinal /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
